let password = document.getElementById("password");
let password_confirm = document.getElementById("password-confirm");
let toggle = document.getElementById("show-password")
toggle.addEventListener('click', () => {
    if (password.type === "password") {
        password.type = "text";
        if(password_confirm)
            password_confirm.type = password.type;
    } else {
        password.type = "password";
        if(password_confirm)
            password_confirm.type = password.type;
    }
});
